import React from 'react'
import { Button } from 'components/buttons/Button'
import styled from 'styled-components'
import useModal from 'hooks/useModal'
import { AlertError } from 'components/design-elements/Notifications'
import { SpinningCircle } from 'components/loader/Spinners'
import SuccessAnimation from 'components/loader/SuccessAnimation'

export default function ConfirmAction({
  submitting = false,
  success = false,
  errorMessage = '',
  preActionMessage = 'Are you sure you want to do this?',
  submittingMessage = 'Submitting',
  postActionMessage = 'All done!',
  buttonText = 'cancel',
  confirmFn,
  ClickComponent,
  ...props
}) {
  const {
    ReactModal,
    setModalIsOpenToTrue,
    setModalIsOpenToFalse,
    modalIsOpen,
    fitToContentModal,
  } = useModal()

  return (
    <>
      <ClickComponent onClick={() => setModalIsOpenToTrue()} {...props}>
        {buttonText}
      </ClickComponent>
      <ReactModal
        isOpen={modalIsOpen}
        style={fitToContentModal}
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        onRequestClose={e => {
          e.stopPropagation()
          setModalIsOpenToFalse()
        }}
      >
        <Container>
          {/******************************************
        M E S S A G E  S T A T E S
         *******************************************/}
          {!submitting && !errorMessage && !success && <Message>{preActionMessage}</Message>}
          {submitting && (
            <SubmittingContainer>
              <SpinningCircle color={'black'} />
              {submittingMessage}
            </SubmittingContainer>
          )}
          {success && <SuccessAnimation message={postActionMessage} />}
          {errorMessage && <AlertError>{errorMessage}</AlertError>}

          {/******************************************
        B U T T O N S
         *******************************************/}
          {!submitting && !errorMessage && !success && (
            <ButtonContainer>
              <Button secondary onClick={() => setModalIsOpenToFalse()}>
                NO
              </Button>
              <Button warning onClick={() => confirmFn()}>
                YES
              </Button>
            </ButtonContainer>
          )}
        </Container>
      </ReactModal>
    </>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
`
const ButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
`
const Message = styled.span`
  font-weight: 800;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
`
const SubmittingContainer = styled.div`
  display: flex;
  gap: 1rem;
  font-weight: 800;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;
`
