import styled from 'styled-components'
import CardHeader from 'components/patterns/CardHeader'
import { PlayerImage } from 'components/patterns/PlayerImage'
import { Pitch, PlayerContainer, Name } from '../Teams.styles'
import { Card } from 'components/design-elements/Card.styled'

export default function SingleTeam({
  manager,
  lastUpdated,
  keeper,
  defenders,
  midfielders,
  forwards,
  subs,
}) {
  return (
    <Card>
      <CardHeader manager={manager} gap={'0.4rem'}>
        {lastUpdated && (
          <>
            <HeaderInfoLabel>Updated</HeaderInfoLabel>
            <HeaderInfoText>{lastUpdated}</HeaderInfoText>
          </>
        )}
      </CardHeader>

      <TeamStarters>
        <TeamStartersContainer>
          <TeamRow>
            <Player id={keeper[0].imgId} name={keeper[0].lastName} />
          </TeamRow>
          <TeamRow>
            {defenders.map(defender => (
              <Player key={defender.imgId} id={defender.imgId} name={defender.lastName} />
            ))}
          </TeamRow>
          <TeamRow>
            {midfielders.map(midfielder => (
              <Player key={midfielder.imgId} id={midfielder.imgId} name={midfielder.lastName} />
            ))}
          </TeamRow>
          <TeamRow>
            {forwards.map(forward => (
              <Player key={forward.imgId} id={forward.imgId} name={forward.lastName} />
            ))}
          </TeamRow>
        </TeamStartersContainer>
        <Pitch src={'/img/assets/pitch.svg'} />
      </TeamStarters>
      {subs.length > 0 && (
        <TeamBench>
          <TeamBenchLabel>Bench</TeamBenchLabel>
          <TeamRow>
            {subs.map(sub => (
              <Player key={sub.imgId} id={sub.imgId} name={sub.lastName} />
            ))}
          </TeamRow>
        </TeamBench>
      )}
    </Card>
  )
}

const Player = ({ id, name }) => {
  return (
    <PlayerContainer>
      <PlayerImage source={id} width='15vw' maxWidth='100px' margin='0 1vw' />
      <Name>{name}</Name>
    </PlayerContainer>
  )
}
const HeaderInfoLabel = styled.p`
  font-size: 0.7rem;
  font-weight: 800;
  line-height: 0.5;
`
const HeaderInfoText = styled.p`
  font-size: 0.7rem;
  font-weight: 400;
`

const TeamStarters = styled.div`
  position: relative;
  padding: ${props => props.padding || '1rem'};
`

const TeamStartersContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  inset: 0;
  padding: 1.5rem 0;
  height: 100%;
  gap: 0.5rem;
`

const TeamRow = styled.div`
  display: flex;
  justify-content: center;
`

const TeamBench = styled.div`
  padding: 0.5rem 1rem 1rem;
  background: var(--color-grey-1);
  border-top: 1px solid var(--color-grey-2);
`
const TeamBenchLabel = styled.p`
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
  font-weight: 800;
  text-align: center;
  text-transform: uppercase;
  color: var(--color-grey-3);
`
