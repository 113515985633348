import React from 'react'
import styled from 'styled-components'
import { Card, CardContainer, CardH1 } from 'components/design-elements/Card.styled'
import { LargeFont } from './Dash.styled'
import { IconActivity } from 'icons'
import NextTransferDate from 'components/patterns/NextTransferDate'

export default function DashPendingTransfers({ transferData }) {
  const numberOfTransfers = transferData.length

  return (
    <>
      {numberOfTransfers > 0 && (
        <Card>
          <CardContainer dashboard>
            <HeaderGrid>
              <Header>
                <CardH1>Pending Transfers</CardH1>
              </Header>
              <Icon>
                <IconActivity />
              </Icon>
            </HeaderGrid>
            <Grid>
              <Transfers>
                <TransfersContainer>
                  <LargeFont>{numberOfTransfers}</LargeFont>
                  <TextBox>received</TextBox>
                </TransfersContainer>
              </Transfers>
              <WindowClosing>
                <WindowClosingHeader>Window closing</WindowClosingHeader>
                <WindowClosingDate>
                  <NextTransferDate />
                </WindowClosingDate>
              </WindowClosing>
            </Grid>
          </CardContainer>
        </Card>
      )}
    </>
  )
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas: 'transfers closing';
`
const HeaderGrid = styled.div`
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-template-rows: auto;
  grid-template-areas: 'header icon';
`
const Header = styled.div`
  grid-area: header;
`
const Icon = styled.div`
  grid-area: icon;
  justify-self: end;
`
const Transfers = styled.div`
  grid-area: transfers;
`
const TransfersContainer = styled.div`
  width: fit-content;
  text-align: center;
  padding-top: 3px;
`
const TextBox = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
`
const WindowClosing = styled.div`
  grid-area: closing;
  padding: 0.5rem 0 0 1rem;
  align-self: center;
  border-left: 1px solid var(--color-grey-2);
  text-align: center;
`
const WindowClosingHeader = styled.div`
  font-size: 0.7rem;
  font-weight: 600;
  line-height: 1.5;
`
const WindowClosingDate = styled.span`
  font-size: 0.9rem;
  font-weight: 700;
`
