import { getDatabase, ref, update, remove, get } from 'firebase/database'
import { useAuth } from 'contexts/AuthContext'
import buildversion from 'buildversion'
import useVersionFlag from './useVersionFlag'

export default function useSetFBdata() {
  const { currentUser } = useAuth()
  const db = getDatabase()
  const { isUK } = useVersionFlag()

  const latestVersion = `v${buildversion.buildMajor}.${buildversion.buildMinor}.${buildversion.buildRevision}`
  const getTimeZone = isUK ? 'Europe/London' : 'America/New_York'
  const region = isUK ? 'UK' : 'US'

  const date = new Date()
  const newDate = date.toLocaleString('en-US', {
    timeZone: getTimeZone,
    dateStyle: 'full',
    timeStyle: 'short',
  })

  const getUserRefs = uid => ({
    manager: ref(db, `private_data/users/${uid}`),
    managersShortlist: ref(db, `private_data/users/${uid}/shortList`),
  })

  /* 
============================================================
Function to update just the new details in the data parameter
============================================================  
*/
  const updateUser = async (ref, data) => {
    try {
      await update(ref, data)
    } catch (error) {
      console.error('Error updating data:', error)
    }
  }

  /* 
============================================================
Function to register the user's latest details in the dB
============================================================  
*/

  const registerVersion = async () => {
    if (currentUser) {
      const { manager } = getUserRefs(currentUser.uid)
      try {
        const snapshot = await get(manager)
        const currentData = snapshot.exists() ? snapshot.val() : {}

        // Detect if the PWA is in standalone mode
        const isStandalone = window.matchMedia('(display-mode: standalone)').matches

        const managerData = {
          ...currentData,
          name: currentUser.displayName,
          region: region,
          appVersion: latestVersion,
          lastActive: newDate,
          photoURL: currentUser.photoURL,
          device: {
            last_ua: navigator.userAgent,
            last_wxh: `${window.innerWidth}x${window.innerHeight}`,
            last_isStandalone: isStandalone,
          },
        }
        await updateUser(manager, managerData)
      } catch (error) {
        console.error('Error fetching current data:', error)
      }
    }
  }

  /* 
============================================================
Function to add a player to the a managers shortlist
============================================================  
*/
  const addToShortList = async (playerData, playerid) => {
    if (currentUser) {
      const { managersShortlist } = getUserRefs(currentUser.uid)
      const updates = { [playerid]: playerData }
      await updateUser(managersShortlist, updates)
    }
  }

  /* 
============================================================
Function to remove a player from the a managers shortlist
============================================================  
*/
  const removeFromShortList = async playerid => {
    if (currentUser) {
      const playerRef = ref(db, `private_data/users/${currentUser.uid}/shortList/${playerid}`)
      try {
        await remove(playerRef)
        console.log(`Player ${playerid} removed from shortlist`)
      } catch (error) {
        console.error('Error removing player:', error)
      }
    }
  }
  /* 
============================================================
Function to remove a player from the a managers shortlist
============================================================  
*/
  const clearShortList = async () => {
    if (currentUser) {
      const shortListRef = ref(db, `private_data/users/${currentUser.uid}/shortList/`)
      try {
        await remove(shortListRef)
        console.log(`Shortlist cleared`)
      } catch (error) {
        console.error('Error clearning shortlist:', error)
      }
    }
  }

  /* 
============================================================
Function to add a players image URL to user object
============================================================  
*/
  const addPhotoURL = async () => {
    if (currentUser) {
      const { manager } = getUserRefs(currentUser.uid)
      const photoURLdata = { photoURL: currentUser.photoURL }
      await updateUser(manager, photoURLdata)
    }
  }

  return {
    registerVersion,
    addToShortList,
    removeFromShortList,
    clearShortList,
    addPhotoURL,
    currentUser,
  }
}
