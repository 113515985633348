import React, { useContext } from 'react'
import { Data } from 'contexts/DataContext'
import { useGwData } from 'hooks/useApiCall'
import { MenuItem } from './MenuItem'
import { menuItems } from './menuItems.data'
import styled from 'styled-components'
import Overlay from 'components/patterns/Overlay'
import { SpinningCircle } from 'components/loader/Spinners'
import NextTransferDate from 'components/patterns/NextTransferDate'
import useVersionFlag from 'hooks/useVersionFlag'

export default function Menu({ open, setOpen, handleLinkClick }) {
  const { gwData } = useGwData()
  const { isUK } = useVersionFlag()
  const { privateDb, fireBaseisLoading } = useContext(Data)
  const spinner = <SpinningCircle color='#000' size='28px' />

  if (fireBaseisLoading) return spinner

  const displayGameWeek = gwData ? gwData[0].displayGameWeek : spinner

  const showFFCup = privateDb.controls.showFFCup
  const showXmasCup = privateDb.controls.showXmasCup
  const betData = isUK ? privateDb.sideBets.uk : privateDb.sideBets.us
  const showSideBets = betData && betData.length > 0

  const filteredMenuItems = menuItems
    .filter(item => (!showXmasCup ? item.path !== 'christmas-cup' : item))
    .filter(item => (!showFFCup ? item.path !== 'ff-cup' : item))
    .filter(item => (!showSideBets ? item.path !== 'side-bets' : item))

  return (
    <>
      <StyledMenu open={open}>
        <InfoBoxGrid>
          <InfoBoxLabel>Game week</InfoBoxLabel>
          <InfoBoxLabel>Window closes</InfoBoxLabel>
          <GameWeek>{displayGameWeek}</GameWeek>
          <WindowDeadline>
            <NextTransferDate />
          </WindowDeadline>
        </InfoBoxGrid>
        <Divider />
        <ScrollableContainer>
          <MenuGrid>
            {filteredMenuItems.map(menuItem => (
              <MenuItem
                key={menuItem.path}
                path={menuItem.path}
                icon={menuItem.icon}
                label={menuItem.label}
                handleLinkClick={handleLinkClick}
              />
            ))}
          </MenuGrid>
        </ScrollableContainer>
      </StyledMenu>
      <Overlay open={open} setOpen={setOpen} />
    </>
  )
}

const StyledMenu = styled.nav`
  background: #f4f4f4;
  transform: ${({ open }) => (open ? 'translateX(0vw)' : 'translateX(70vw)')};
  height: calc(100vh - 56px);
  width: 70vw;
  text-align: left;
  position: fixed;
  top: 0;
  right: 0;
  transition: transform 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
  color: #000;
  z-index: var(--zi-menu);
`

const InfoBoxGrid = styled.div`
  display: grid;
  grid-template-columns: 3fr 4fr;
  margin: 1.3rem 1rem 1rem 1rem;
  place-items: center;
  text-align: center;
`

const InfoBoxLabel = styled.h2`
  font-size: 0.7rem;
  text-transform: uppercase;
  color: #252525;
`
const GameWeek = styled.div`
  font-size: 2rem;
  font-weight: 900;
  margin: 0;
`
const WindowDeadline = styled.div`
  font-size: 0.8rem;
  font-weight: 600;
  margin: 0;
`
const Divider = styled.hr`
  margin: 0 1.5rem;
  border: none;
  height: 0.5px;
  background-color: var(--color-grey-3);
`

const MenuGrid = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1rem 1rem 1rem;
  justify-content: space-between;
  min-height: 600px;
  a {
    border-radius: 10px;
  }
  a:hover {
    background: #ffffff;
  }
`

const ScrollableContainer = styled.div`
  height: calc(100vh - 145px);
  overflow-y: auto;
  position: relative;
`
