import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Button } from 'components/buttons/Button'

export default function DashQuickLinks({ canUpdateTeam = false, isInAuctionRoom = false }) {
  return (
    <Flex>
      {isInAuctionRoom && (
        <Link to='/auction-room' style={{ width: '100%' }}>
          <Button warning>Auction Room</Button>
        </Link>
      )}
      {canUpdateTeam && (
        <Link to='/teams/my-team' style={{ width: '100%' }}>
          <Button primary>Update team</Button>
        </Link>
      )}
    </Flex>
  )
}

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 1rem;
`
